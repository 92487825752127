export const apiDomain = 'https://getheli.com/api';
export const landingSitesDomain = 'https://api.helipaddy.com/api/external/pads';
export const landingSitesApiKey = '$2y$10$w5A90rfLTtywnt4pD.gl5OGiK';
export const websocketPath = 'https://socket.getheli.com';
export const stripePublicKey =  'pk_live_yGzLqE63DoNbBx3frNCfkrwr';
//export const stripePublicKey =  'pk_test_CL3pi5975POi9fakL3Ikex8s';
export const ga = 'UA-20908921-15';
export const staticFilesVersion = 2;
export const iframeToken = "O7dtmWh6rHFNlhyCl8DU";
export const url = "https://external.getheli.com";
export const pdfUri = "https://pdf.getheli.com/";
export const REACT_APP_URL = "https://getheli.com/";
export const IFRAME_APP_URL = "https://external.getheli.com/";
export const deployment_version = 75;
